// SCREEN SIZE DEFINITIONS
// Small - Large Tablets
$screen-sm-min: 650px;

// Small PC Devices
$screen-sm-pc: 950px;

// PC Devices
$screen-md-pc: 1200px;

// Large Devices
$screen-lg-pc: 1440px;

//-----------------------------------------------------------

// Tablet devices
@mixin tablet {
  @media (min-width: #{$screen-sm-min}) {
    @content;
  }
}

// Small PC devices
@mixin sm-pc {
  @media (min-width: #{$screen-sm-pc}) {
    @content;
  }
}

// PC Devices
@mixin md-pc {
  @media (min-width: #{$screen-md-pc}) {
    @content;
  }
}

// Large PC devices
@mixin lg-pc {
  @media (min-width: #{$screen-lg-pc}) {
    @content;
  }
}

//-----------------------------------------------------------

$text: "Barlow";
$weight: 300;
$style: uppercase;

//-----------------------------------------------------------

.servicesCard {
  width: 128px;
  max-width: 150px;
  height: 100px;
  background-color: rgba(#000, 0.2);
  border-radius: 5px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  // 950px Breakpoint ---------------------------------------
  @include sm-pc {
    width: 150px;
  }

  // 1200px Breakpoint ---------------------------------------
  @include md-pc {
    width: 200px;
    height: 120px;
  }
}

.servicesCard p {
  font-family: $text;
  font-weight: $weight;
  text-transform: $style;

  // 950px Breakpoint ---------------------------------------
  @include sm-pc {
    font-size: 18px;
    margin: 10px 0;
  }
}

// Image Cards

.imageCards {
  width: 100%;
  max-width: 330px;
  height: 400px;

  margin: 10px auto;

  overflow: hidden;
}

.imageCards img {
  width: 335px;
  height: 400px;

  object-fit: cover;
}

// Review Cards

.reviewCard {
  width: 100%;
  max-width: 350px;
  min-height: 220px;
  padding: 20px;

  border: 2px solid #fdaeae;

  display: grid;
  grid-template-rows: 87% 13%;
}

.reviewCard hr {
  border: none;
  border-top: 1px solid rgba(#000, 0.3);
  margin-top: 20px;
}

.reviewCard h5 {
  font-family: $text;
  font-weight: 400;
  font-size: 18px;
}

.reviewCard span {
  font-size: 50px;
}

.reviewCard p {
  font-size: 15px;
}

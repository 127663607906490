// SCREEN SIZE DEFINITIONS
// Small - Large Tablets
$screen-sm-min: 650px;

// Small PC Devices
$screen-sm-pc: 950px;

// PC Devices
$screen-md-pc: 1200px;

// Large Devices
$screen-lg-pc: 1440px;

// Tablet devices
@mixin tablet {
  @media (min-width: #{$screen-sm-min}) {
    @content;
  }
}

// Small PC devices
@mixin sm-pc {
  @media (min-width: #{$screen-sm-pc}) {
    @content;
  }
}

// PC Devices
@mixin md-pc {
  @media (min-width: #{$screen-md-pc}) {
    @content;
  }
}

// Large PC devices
@mixin lg-pc {
  @media (min-width: #{$screen-lg-pc}) {
    @content;
  }
}

//-----------------------------------------------------------

// Font Variables.
$body-text: "Barlow";
$thin-weight: 300;

//-----------------------------------------------------------

nav {
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding: 0 24px;

  // 650px window width
  @include tablet {
    height: 120px;

    padding: 0 40px;
  }

  // Small PC Viewport
  @include sm-pc {
    padding: 0 80px;
  }

  @include lg-pc {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0;
  }

  .navHeader {
    height: 65px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding-top: 15px;

    // 650px window width
    @include tablet {
      height: 85px;
    }

    img {
      width: 100px;

      // Small PC viewport
      @include sm-pc {
        width: 130px;
      }
    }

    a {
      font-family: $body-text;
      font-size: 15px;

      text-decoration: none;
      color: #000;

      // Small PC viewport
      @include sm-pc {
        font-size: 24px;
      }
    }
  }

  .navLinks {
    height: 35px;

    display: flex;
    align-items: flex-end;

    ul {
      display: flex;

      li {
        margin-right: 20px;

        //650px window width
        @include tablet {
          margin-right: 30px;
        }

        a {
          font-family: $body-text;
          font-size: 15px;

          text-transform: uppercase;
          text-decoration: none;
          color: rgba(#000000, 0.5);
        }

        a:hover {
          color: rgba(#000000, 0.8);
        }
      }
    }
  }
}

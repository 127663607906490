// SCREEN SIZE DEFINITIONS
// Small - Large Tablets
$screen-sm-min: 650px;

// Small PC Devices
$screen-sm-pc: 950px;

// PC Devices
$screen-md-pc: 1200px;

// Large Devices
$screen-lg-pc: 1440px;

// Tablet devices
@mixin tablet {
  @media (min-width: #{$screen-sm-min}) {
    @content;
  }
}

// Small PC devices
@mixin sm-pc {
  @media (min-width: #{$screen-sm-pc}) {
    @content;
  }
}

// PC Devices
@mixin md-pc {
  @media (min-width: #{$screen-md-pc}) {
    @content;
  }
}

// Large PC devices
@mixin lg-pc {
  @media (min-width: #{$screen-lg-pc}) {
    @content;
  }
}
//-----------------------------------------------------------

footer {
  height: 300px;
  background-color: #d6d6d6;

  display: flex;

  @include tablet {
    height: 180px;
  }

  .footerWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;

    margin: 0 24px;

    @include tablet {
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      margin: 0 40px;
    }

    @include sm-pc {
      margin: 0 80px;
    }

    @include lg-pc {
      width: 1200px;
      margin: 0 auto;
    }

    div {
      margin-right: 50px;

      @include sm-pc {
        margin-right: 70px;
      }

      @include md-pc {
        margin-right: 180px;
      }

      @include lg-pc {
        margin-right: 250px;
      }
    }

    h5 {
      font-family: "Work Sans";
      font-size: 18px;
    }

    li {
      margin: 10px 0;

      a {
        font-family: "Barlow";
        font-size: 15px;
        font-weight: 300;

        text-decoration: none;
        color: rgba(#000000, 0.7);
      }
    }

    a {
      text-decoration: none;
      color: #000;
    }

    p {
      font-family: "Barlow";
      font-weight: 300;
      font-size: 12px;

      margin: 20px 0;
    }
  }
}

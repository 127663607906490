// SCREEN SIZE DEFINITIONS
// Small - Large Tablets
$screen-sm-min: 650px;

// Small PC Devices
$screen-sm-pc: 950px;

// PC Devices
$screen-md-pc: 1200px;

// Large Devices
$screen-lg-pc: 1440px;

//-----------------------------------------------------------

// Tablet devices
@mixin tablet {
  @media (min-width: #{$screen-sm-min}) {
    @content;
  }
}

// Small PC devices
@mixin sm-pc {
  @media (min-width: #{$screen-sm-pc}) {
    @content;
  }
}

// PC Devices
@mixin md-pc {
  @media (min-width: #{$screen-md-pc}) {
    @content;
  }
}

// Large PC devices
@mixin lg-pc {
  @media (min-width: #{$screen-lg-pc}) {
    @content;
  }
}

//-----------------------------------------------------------

// Font Variables.
$heading-text: "Work Sans";
$heading-weight: 800;

// ----------------------------------------------------------
.homeWrapper {
  margin: 20px 24px;

  // 650px Breakpoint ---------------------------------------
  @include tablet {
    margin: 20px 40px;
  }

  // 950px Breakpoint ---------------------------------------
  @include sm-pc {
    margin: 20px 80px;
  }

  // 1440px Breakpoint --------------------------------------
  @include lg-pc {
    max-width: 1200px;
    margin: 20px auto;
  }
}

.heroSection {
  margin-bottom: 50px;
  h1 {
    font-family: $heading-text;
    font-weight: $heading-weight;
    font-size: 38px;

    margin: 20px 0;

    // 650px Breakpoint -------------------------------------
    @include tablet {
      font-size: 50px;
    }

    // 950px Breakpoint -------------------------------------
    @include sm-pc {
      font-size: 65px;
    }
  }

  .heroImage {
    width: 100%;
    height: 400px;
    border-radius: 5px;

    background-image: url("../../../images/main-image.jpg");
    background-size: cover;

    margin: 50px auto;

    // 950px Breakpoint --------------------------------------
    @include sm-pc {
      height: 500px;
    }

    // 1200px Breakpoint -------------------------------------
    @include md-pc {
      height: 600px;
    }
  }

  h2 {
    font-family: $heading-text;
    font-weight: $heading-weight;
    font-size: 32px;

    // 1200px Breakpoint ------------------------------------
    @include md-pc {
      font-size: 40px;
    }
  }
}

.servicesSection {
  h3 {
    font-family: $heading-text;
    font-weight: $heading-weight;
    font-size: 24px;

    margin-bottom: 30px;
  }

  .serviceCardDiv {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px;

    // 650px Breakpoint ------------------------------------
    @include tablet {
    }
  }
}

.imageSection {
  .imgOne {
    width: 327px;
    height: 420px;

    background-image: url("../../../images/media-wall.jpg");
    background-size: cover;

    margin: 30px auto;
    border-radius: 5px;

    // 650px Breakpoint ------------------------------------
    @include tablet {
      position: absolute;
      top: 50px;
      left: 40%;
    }

    // 950px Breakpoint ------------------------------------
    @include sm-pc {
      left: 49%;
    }

    // 1440px Breakpoint ------------------------------------
    @include lg-pc {
      width: 400px;
      height: 480px;
    }
  }

  .imgTwo {
    width: 327px;
    height: 480px;

    background-image: url("../../../images/panelling.jpeg");
    background-size: cover;

    margin: 30px auto;
    border-radius: 5px;

    // 650px Breakpoint ------------------------------------
    @include tablet {
      position: absolute;
      top: 200px;
      right: 40%;
    }

    // 950px Breakpoint ------------------------------------
    @include sm-pc {
      right: 46%;
    }

    // 1440px Breakpoint ------------------------------------
    @include lg-pc {
      width: 400px;
      height: 500px;

      background-position: top;
    }
  }

  // 650px Breakpoint ------------------------------------
  @include tablet {
    height: 750px;
    position: relative;
  }
}

.ctaSection {
  display: flex;
  flex-direction: column;
  align-items: center;

  margin: 30px auto;

  h4 {
    font-family: $heading-text;
    font-weight: $heading-weight;
    font-size: 24px;

    text-align: center;

    margin-bottom: 30px;

    // 650px Breakpoint ------------------------------------
    @include tablet {
      font-size: 28px;
    }

    // 1440px Breakpoint ------------------------------------
    @include lg-pc {
      font-size: 40px;

      margin-top: 30px;
    }
  }
}

.reviewsSection {
  margin: 30px auto;

  .reviewWrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: center;

    max-width: 800px;
    margin: 0 auto;
  }

  h4 {
    font-family: $heading-text;
    font-size: 24px;
    margin: 20px auto;
    max-width: 800px;

    // 950px Breakpoint ------------------------------------
    @include sm-pc {
      padding-left: 30px;
    }
  }

  .topDiv {
    border-bottom: 1px solid rgba(#000000, 0.5);
    margin-bottom: 10px;
  }
}

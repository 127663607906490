// SCREEN SIZE DEFINITIONS
// Tablets
$screen-sm-min: 650px;

// Small PC Devices
$screen-sm-pc: 950px;

// PC Devices
$screen-md-pc: 1200px;

// Large Devices
$screen-lg-pc: 1440px;

// Tablet devices
@mixin tablet {
  @media (min-width: #{$screen-sm-min}) {
    @content;
  }
}

// Small PC devices
@mixin sm-pc {
  @media (min-width: #{$screen-sm-pc}) {
    @content;
  }
}

// PC Devices
@mixin md-pc {
  @media (min-width: #{$screen-md-pc}) {
    @content;
  }
}

// Large PC devices
@mixin lg-pc {
  @media (min-width: #{$screen-lg-pc}) {
    @content;
  }
}

//-----------------------------------------------------------

$headings: "Work Sans";
$body: "Barlow";

//-----------------------------------------------------------

.form {
  width: 100%;
  max-width: 700px;
  background-color: rgba(#000, 0.1);
  padding: 10px;
  border-radius: 3px;
  margin-bottom: 30px;

  @include tablet {
    padding: 20px;
  }

  .formGroup {
    display: flex;
    flex-direction: column;
    margin: 10px 0;

    label {
      font-family: $body;
      color: rgba(#000, 0.7);
      margin-bottom: 2px;

      @include tablet {
        margin-bottom: 5px;
      }
    }

    input,
    textarea {
      width: 100%;
      max-width: 700px;
      border: 1px solid #000;
      border-radius: 3px;

      font-family: $body;
    }

    input {
      text-indent: 5px;
      height: 30px;

      // Tablet Viewport
      @include tablet {
        height: 40px;
      }
    }

    textarea {
      resize: vertical;
      padding: 5px;
    }

    input:focus,
    textarea:focus {
      outline: none;
    }
  }
}

.formSuccess {
  width: 100%;
  max-width: 700px;
  height: 400px;
  background-color: rgba(#000, 0.1);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  border-radius: 3px;
  margin-bottom: 30px;
  padding: 20px;

  h4 {
    font-family: $body;
    font-weight: 400;
    font-size: 24px;
    margin: 20px;
  }

  p {
    font-family: $body;
    max-width: 500px;
    text-align: center;
  }
}
